.chatContainer {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    height: calc(100vh - 260px);

    flex-direction: column;
}

.chatSection {
    padding: 20px;
    flex: 1;
    height: 100%;
    overflow-y: scroll;
}

.messageDisabledText {
    font-size: 12px;
    color: rgb(172, 168, 168);
    font-size: 400;
    margin: 0;
}

.chatSection::-webkit-scrollbar {
    width: 16px;
}

.chatSection::-webkit-scrollbar-track {
    background: transparent;
}

.chatSection::-webkit-scrollbar-thumb {
    height: 6px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 10px;
    background-color: #ced0d4;
}

.chatSection::-webkit-scrollbar-thumb:hover {
    background-color: #ced0d4;
}


.chatBlock {
    margin-bottom: 30px;
}

.sender {
    font-weight: 500;
    font-size: 14px;
    color: rgb(189, 189, 189);

}


h3 {
    margin: 0;
    font-weight: 400;
}

.icon {
    cursor: pointer;
    font-size: 20px;
    margin-left: 10px;
    color: #EA4D1B;
}


.inputMessage {
    padding: 5px 10px 5px 10px;
    border: none !important;
    outline: none !important;
    border-bottom: 1px solid #eee;
    width: 100%;
    font-size: 13px;
    overflow-y: auto !important;
    background-color: transparent;
    resize: none;
    color: white;
    overflow-y: scroll;

}

.sendMsgSection {

    border-top: 1px solid rgb(97, 97, 97);
    display: flex;
    padding: 10px;
    align-items: center;
    justify-content: space-between;
}


.small {
    margin-left: 5px;
    color: rgb(189, 189, 189);
    font-weight: 300;
}

.textMessage {
    margin-left: 10px;
    margin: 0;
    white-space: pre-wrap;
    word-wrap: break-word;
    padding-top: 5px;
    color: white;
    font-size: 14px;
}

.sendMsgSection {
    padding: 20px;
    border-top: 1px solid rgb(107, 103, 103);

    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #555;
}


.sendMsgSectionDisabled {
    padding: 20px;
    border-top: 1px solid rgb(107, 103, 103);

    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #555;
}

.textMessage:active {
    border-bottom: 2px solid #EA4D1B;
    color: #EA4D1B;
}

.textMessage:hover {
    cursor: pointer;
    background: rgba(0, 121, 107, 0.039);
}


@media screen and (max-width: 932px) {
    .chatContainer {

        height: calc(100vh - 320px);

    }

}