.numberMessagesContainer {
    left: 75%;
    top: 55%;
    border-radius: 50%;
    background-color: #fa3e3e;
    height: 18px;
    width: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
}

.numberNotifText {
    color: white !important;
    font-weight: bold !important;
    font-size: 10px !important;
}

.dropDownToggle {
    padding: 0 !important;
    margin: auto;
    border-radius: 50%;
    height: 42px;
    width: 42px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background-color: transparent;
    background-color: unset !important;
}

.dropDownToggle:hover {
    background-color: unset !important;
}

.messagesContainer {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #e4e4e4;
    padding: 0 !important;
}

.messagesContainer:hover {
    background-color: #ccc;
}

.navMainIcons {
    font-size: 16px;
    color: black;
    padding: 2px;
}
