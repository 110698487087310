.footerItem {
    width: 100%;
    padding: 10px;
    height: fit-content;
    display: flex !important;
    background-color: #202124;

    flex-direction: row !important;
    margin: 0 !important;
}

.leftItemIconBlock {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px 10px 20px;

}

.leftItemIcon {
    margin-left: 10px;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.leftItemIconBlock:hover {
    cursor: pointer;
    background: #eee;
}

.centerItem {
    display: flex;
    align-items: center;
    border-radius: 50px;
    padding: 10px;
    justify-content: center;
}

.emojiText {
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
    border-radius: 50%;
    height: 35px;
    padding-right: 0px;
    width: 35px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    font-size: 20px;
}

.raiseHandContainer {
    padding: 10px;
    cursor: pointer;
    background-color: #202124;
    border-radius: 10px;
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.raiseHandText {
    font-size: 12px;
    color: white;
    font-weight: 600;
    margin: 0;
}

.reactionContainer {
    position: absolute;
    bottom: 80px;
    border-radius: 10px;
    background-color: black;
    z-index: 99999;
    left: -120px;
    height: 100px;
    width: 330px;
}

.reactIconBlock {
    background: #36373A;
    border: 1px solid #36373A;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.emojiText:hover {
    background: #36373A;


}

.centerItemIconBlock {
    background: #36373A;
    border: 1px solid #36373A;
    min-width: 45px;
    min-height: 45px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.centerItemIconBlockPhone {
    background: #d93025;
    border: 1px solid #d93025;
    min-width: 45px;
    min-height: 45px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

}

.centerItemIcon {
    color: white;
    font-size: 17px;

}

.red {
    color: #d93025;
}

.centerItemIconBlock:hover {
    cursor: pointer;
    box-shadow: 0 0 10px #ddd;
}

.redBg {
    background: #d93025;
}

.badgeContainer {
    position: absolute;
    top: -20px;
    right: -5px;
    background-color: #5e5e5e;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    display: flex;
}

.badgeText {
    color: white;
    font-size: 13px;
    margin: 0;
}

.activeIcon {
    color: #EA4D1B !important;

}

.activeBgIcon {
    background-color: #1F2144;
}



.rightItem {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.rightItemIconBlock {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px 10px 20px;
}

.rightItemIcon {
    font-size: 20px;
    margin-right: 10px;
}

.title {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
}

.rightItemIconBlock:hover {
    cursor: pointer;
    background: #eee;
}


.headerItems {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 10px;

}

.alertCircleIcon {
    position: absolute;
    background: #EA4D1B;
    width: 10px;
    height: 10px;
    border-radius: 50%;

    top: 10px;
    right: 20px;
}

.dateBlock {
    font-size: 20px;
    color: #999999;
    font-weight: 400;
    margin: 0;

    font-weight: 400;
}

.profile {
    font-size: 32px;
    color: #1F2144;
}

.icon {
    color: white;
    font-size: 22px;
    cursor: pointer;

}

.userImage {
    height: 30px;
    width: 30px;
    object-fit: cover;
    border-radius: 50%;
}

.meetInfoText {
    color: white;
    font-weight: 400;
    font-size: 14px;
    margin: 0;
}

@media screen and (max-width: 767px) {
    .footerItem {
        justify-content: flex-start !important;
        align-items: flex-start !important;
        flex-direction: column !important;
    }
}