.mainContainer {
    height: 150px;
    width: 250px;
    border-radius: 10px;
    bottom: 60px;
    right: 60px;
    z-index: 999;
    position: absolute;
    background-color: #4A4E51;



}
.minimizedMainContainer{
    height: 50px;
    width: 250px;
    border-radius: 10px;
    bottom: 60px;
    right: 60px;
    z-index: 999;
    position: absolute;
    background-color: #4A4E51;   
}
.minimizedControlContainer{
    position: absolute;
    top: 0;
    left:0;
    height:100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.youText{
    font-size: 13px;
    color:white;
    margin: 0;
    font-weight: 600;
    margin-left: 5px;
    margin-right: 5px;
}
.controlContainer{
    position: absolute;
    top: 0;
    left:0;
    height:100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.mainUserInfoContainer {
    display: flex;
    position:relative;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.menuControlItem {
    height: 35px;
    width: 35px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    background-color: rgba(48, 44, 44, 0.7);
    justify-content: center;
    position: relative;
    margin-left: 5px;
    margin-right: 5px;
}

.menuItem {
    height: 35px;
    width: 35px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-left: 5px;
    margin-right: 5px;
}

.menuControlItem:hover {
    background-color: rgba(48, 44, 44, 1);
}
.controlIcon {
    font-size: 17px;
    color: rgb(209, 209, 209);
    cursor: pointer;
}

.userImage {
    height: 60px;
    width: 60px;
    border-radius: 50%;
}

.videoContainer {

    height: 100%;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;

}

.video {
    top: 0;
    left: 0;
    object-fit: fill;
    width: 100%;
    height: 100%;
}



.centerItemIconBlock {
    background-color: black;
    z-index: 99;
    width: 25px;
    height: 25px;
    position: absolute;
    top: 10px;
    right: 0px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px;

}

.microIcon {
    color: white;
    font-size: 13px;
}