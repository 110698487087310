* {
    font-family: 'Poppins', sans-serif;
}

button {
    box-shadow: none !important;
}

body {
    background-color: #f8f9ff;
    padding-right: 0px !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
    margin: 0;
    padding: 0;
    color: #1F2144;
}

a:hover {
    color: #4d4f76;

}

.dropdown-nav .dropdown-menu.show {
    width: 300px !important;
    padding: 5px 0px 0px 0px !important;
    margin-top: 5px !important;
    left: -200% !important;
    width: 350px !important;
}

.dropdown-nav .dropdown-menu.show::before {
    left: 50% !important;
    height: 20px !important;
    top: -10 !important;
    margin: 0 !important;
    z-index: 999999 !important;
}

.MuiSwitch-colorPrimary.Mui-checked {
    color: #EA4D1B !important;
}

.MuiSwitch-track {
    background-color: #EA4D1B !important;
}

.MuiIconButton-colorPrimary {
    color: #EA4D1B !important;
}

.MuiFormLabel-root.Mui-focused {
    color: #EA4D1B !important;

}

.MuiInput-underline:after {
    border-bottom-color: #EA4D1B !important;
}

.MuiBadge-colorPrimary {
    background-color: #EA4D1B !important;
    font-weight: 600 !important;
}

.swal2-title {
    color: black !important;
    font-family: 'Poppins', sans-serif !important;
}

.swal2-modal {
    border-radius: 20px !important;
}

.swal2-confirm {
    font-family: 'Poppins', sans-serif !important;
    color: white !important;
    font-weight: 600 !important;
    background-color: #E3720B !important;
}