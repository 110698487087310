.myModal {
    background-color: transparent !important;
    border-radius: 50px !important;
    border: none !important;
}

.leftSide {
    background-color: #fafafb;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    padding: 20px !important;
}

.topSection {
    display: flex;
    align-items: center;
}

.rightSide {
    background-color: #fff;
    border-bottom-right-radius: 50px;
    border-top-right-radius: 50px;
    padding: 25px 0px 25px 30px !important;
}

.closeButton {
    position: absolute;
    top: -13px;
    right: -20px;
    width: 48px;
    height: 48px;
    border-radius: 50px;
    background-color: #44444f;
    border-style: solid;
    border-width: 5px;
    border-color: white;
    cursor: pointer;
}

.logo {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
}

.registerButton {
    width: 100%;
    padding: 10px;
    background-color: white;
    color: #56bc80;
    font-weight: 600;
    font-size: 0.65em;
    font-family: 'Poppins', sans-serif;
    border: none;
    border-radius: 10px;
    /* margin-top: 1px; */
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
}

.topSection {
    display: flex;
    justify-content: space-between;
}

.jobSeekerImg {
    width: 100%;
    height: 100%;
}

.bottomSection {
    display: flex;
    justify-content: center;
    margin-top: 30px !important;
}

.jobSeekerTitle {
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    margin-top: 20px;
}

.title {
    margin-bottom: 30px;
    margin-top: 15px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}

.rememberMe {
    display: flex;
    align-items: center;
    color: #696974;
    margin-left: -10px;
}

.rememberText {
    /* margin: 0; */
    font-size: 0.9em;
}

.rememberText {
    margin: 0;
    font-size: 1em;
}

.forgot {
    text-decoration: none;
    color: #1F2144;
    font-size: 0.9em;
    font-weight: bold;
}

.loginButton {
    width: 90%;
    padding: 10px;
    color: white;
    background-color: #56bc80;
    border: none;
    border-radius: 25px;
    margin-bottom: 5%;
    margin-top: 15px;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 0.9em;
}

.separator {
    margin-left: -15px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.instantLogin {
    width: 90%;
    text-align: center;
    border-bottom: 1px dashed #e2e2ea;
    line-height: 0.1em;
}

.instantLogin span {
    background: #fff;
    padding: 0 10px;
    color: #b5b5be;
}

.googleButton {
    width: 90%;
    padding: 10px;
    color: white;
    background-color: #fc5a5a;
    border: none;
    border-radius: 25px;
    margin-top: 20px;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9em;
}

.fbButton {
    width: 90%;
    padding: 10px;
    color: white;
    background-color: #0062ff;
    border: none;
    border-radius: 25px;
    margin-top: 20px;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9em;
}

.iconGoogle {
    margin-right: 20px;
}

.iconFacebook {
    margin-right: 5px;
}

@media only screen and (max-width: 992px) {
    .leftSide {
        display: none;
    }
    .rightSide {
        background-color: #fff;
        border-bottom-right-radius: 50px;
        border-bottom-left-radius: 50px;
        border-top-left-radius: 50px;
        padding: 20px;
    }
}

@media only screen and (max-width: 768px) {
    .leftSide {
        display: none;
    }
    .rightSide {
        background-color: #fff;
        border-bottom-right-radius: 50px;
        border-bottom-left-radius: 50px;
        border-top-left-radius: 50px;
        padding: 20px;
    }
    .closeButton {
        position: absolute;
        top: -13px;
        right: -3px;
    }
    .container {
        margin: 0px 10px !important;
    }
    .rememberMe,
    .forgot {
        display: flex;
        justify-content: center;
    }
}