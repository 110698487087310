.logoContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
}

.logo {
    width: 255px;
    margin-top: 30px;

}

@media only screen and (max-width: 600px) {
    .logo {
        width: 200px;

    }
}

.contentContainer {
    display: flex;
    justify-content: center;
}

.whiteSection {
    background-color: white;
    box-shadow: 5px 5px 20px 1px #8888882d;
    border-radius: 20px;
    margin-bottom: 20px;
}

.textSection {
    padding: 50px 40px 30px !important;
}

.notRecived {
    color: #707070;
    font-size: 0.9em;
}

.verifyBtn {
    background-color: #00dfc0;
    width: 100%;
    padding: 10px;
    margin-top: 20px !important;
    color: white !important;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 0.9em;
}

.errorTag {
    color: red;
    font-weight: 500;
    font-size: 0.9em;
    text-align: center;
}