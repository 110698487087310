.conversation_list_item {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #eeeef1;
}

.conversation_list_item:hover {
  background: #eeeef1;
  cursor: pointer;
}

.conversation_photo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.conversation_title {
  font-size: 14px;
  font-weight: bold;
  text-transform: capitalize;
  margin: 0;
  padding-right: 10px;
  width: 160px;
}

.conversation_time {
  font-size: 14px;
  color: #888;
  margin: 0;
}

.conversation_snippet {
  font-size: 14px;
  color: #888;
  margin: 0;
}
