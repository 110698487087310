.page {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center !important;
    flex-direction: column;
}

.logo {
    margin-top: 30px;
    margin-left: 10px;
    width: 250px;
}

@media only screen and (max-width: 600px) {
    .logo {
        width: 200px;

    }
}

.titleContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-bottom: 10px;
}

.pageTitle {
    color: #44444f;
}

.cote {
    color: #92929d;
    font-size: 15px;
    margin-top: 10px !important;
    font-family: 'Roboto', sans-serif;
}

.principalContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    margin-top: 20px !important;
}

.col1 {
    display: flex;
    justify-content: center;
    align-items: center;
}

.col2 {
    padding: 15px 10px;
    background-color: white;
    box-shadow: 5px 5px 20px 1px #8888882d;
    border-radius: 20px;
}

.jobSeekerImg {
    width: 100%;
    height: 100%;
}

.row {
    width: 100%;
}

.field {
    width: 100%;
}

.errorTag {
    color: red;
    font-weight: 500;
    font-size: 0.9em;
    text-align: left;
}

.errorTagEmpty {
    color: red;
    font-weight: bold;
}

.googleButton {
    width: 100%;
    height: 48px;
    margin-top: 20px !important;
    color: white;
    background-color: #fc5a5a;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 0.9em;
}

.fbButton {
    width: 100%;
    height: 48px;
    /* margin-left: 4% !important; */
    margin-top: 20px !important;
    color: white;
    background-color: #0062ff;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 0.9em;
}

.icon {
    margin-right: 0.5vw;
}

.loginButton {
    width: 100%;
    height: 48px;
    margin-top: 20px !important;
    color: black !important;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 0.9em;
}

.startButton {
    width: 100%;
    height: 48px;
    margin-top: 20px !important;
    color: white;
    background-color: #1F2144;
    border: none;
    border-radius: 10px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 0.9em;
    transition: all 0.1s linear;
}

.startButton:disabled {
    cursor: not-allowed;
    background-color: #b5b5be;
}

.agree {
    color: #696974;
}

.switch {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 15px !important;
    margin-bottom: 15px !important;
}

.switchButton {
    background: #f8f9ff;
    border-radius: 30px !important;
    overflow: hidden !important;
    width: 240px !important;
    text-align: center !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    color: #1F2144;
    position: relative !important;
    padding-right: 120px !important;
    padding-left: 0px !important;
    position: relative !important;
}

.switchButton:before {
    content: 'Consultant' !important;
    position: absolute !important;
    color: #1F2144;
    top: 0 !important;
    bottom: 0 !important;
    right: 0 !important;
    width: 120px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    z-index: 3 !important;
    pointer-events: none !important;
}

.switchButtonCheckbox {
    cursor: pointer;
    position: absolute !important;
    top: 0 !important;
    right: 0 !important;
    left: 0 !important;
    bottom: 0 !important;
    width: 100% !important;
    height: 100% !important;
    opacity: 0 !important;
    z-index: 2 !important;
}

.switchButtonCheckbox:checked+.switchButtonLabel:before {
    transform: translateX(120px);
    transition: transform 300ms linear;
}

.switchButtonCheckbox+.switchButtonLabel {
    position: relative !important;
    padding: 15px 0 !important;
    display: block !important;
    user-select: none !important;
    pointer-events: none !important;
}

.switchButtonCheckbox+.switchButtonLabel:before {
    content: '' !important;
    background: #0080ff34;
    height: 100% !important;
    width: 100% !important;
    position: absolute !important;
    left: 0 !important;
    top: 0 !important;
    right: 0 !important;
    border-radius: 30px !important;
    transform: translateX(0);
    transition: transform 300ms;
}

.switchButtonCheckbox+.switchButtonLabel .switchButtonLabelSpan {
    position: relative !important;
}

@media only screen and (max-width: 768px) {
    .container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .titleContainer {
        margin-top: 30px !important;
    }

    .logoColumn {
        display: flex;
        justify-content: center;
    }
}