.mainContainer {
    background-color: #EA4D1B;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.waveItem {
    background-color: black;
    width: 4px;
    transition: all linear 0.1s;

}