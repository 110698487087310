.logoContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
}

.logo {
    width: 255px;
    margin-top: 30px;
}

@media only screen and (max-width: 600px) {
    .logo {
        width: 200px;

    }
}

.contentContainer {
    display: flex;
    justify-content: center;
}

.whiteSection {
    background-color: white;
    box-shadow: 5px 5px 20px 1px #8888882d;
    border-radius: 20px;
    margin-bottom: 30px;
}

.textSection {
    padding: 50px 40px !important;
}

.notRecived {
    color: #707070;
    font-size: 0.9em;
}