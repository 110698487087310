.userProfileToggle {
    margin: auto;
    padding: 5px 10px 5px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
}

.navItem {
    color: black;
    margin: 0;
    font-weight: 600;
    padding: 10px;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
}

.avatar {
    width: 35px;
    height: 35px;
    background-position: 50% 50%;
    background-repeat: none;
    background-size: cover;
    border-radius: 50%;
}

.userNameText {
    padding: 5px;
    font-size: 14px;
    color: black;
}
