.mainContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 80px);
}


.helpText {
    margin-top: 30px;
    padding-top: 25px;
    border-top: 1px solid #ddd;
}

.rightSideMainContainer {
    display: block;
}

.rightSideContent {
    border: 1px solid #eee;
    padding: 30px;
    width: 100%;
    max-width: 650px;
    border-radius: 5px;
    box-sizing: border-box;
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302),
        0 2px 6px 2px rgba(60, 64, 67, 0.149);
}

.rightSideimg {
    width: 100%;
    border-radius: 5px;
}


.leftSide {
    max-width: 500px;
    display: flex;
    align-items: center;

}

.pageTitle {
    font-size: 44px;
    font-weight: 400;
    margin: 0;
}

.pageDescription {
    font-size: 18px;
    font-weight: 300;
}

.actionBtn {
    margin-top: 30px;
}

.newMeetingButton {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    padding: 15px;
    border: none;
    outline: none;
    background: none;
    border-radius: 5px;
    color: white;
    width: 100%;
    background-color: #1F2144;
    text-decoration: none;
}

.joinBtn {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    padding: 15px;
    border: none;
    outline: none;
    background: none;
    border-radius: 5px;
    color: #1F2144;
    margin-left: 10px;

    text-decoration: none;
}

.iconBlockBtn {
    padding-right: 10px;
}

.joinBtn:hover {
    background: #dadada;
}

.inputBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.inputSection {
    position: relative;
    width: 100%;

}

.iconBlockBtnSection {
    position: absolute;
    top: 50%;
    transform: translate(10px, -50%);
    color: #5f6368;
}


.inputCode {
    font-size: 16px;
    height: 48px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding-left: 35px;
    width: 100%;
    box-sizing: border-box;
    outline: none;
}

.errorMessageContainer {
    margin-top: 10px;
    margin-bottom: 10px;
}

.errorText {
    text-align: center;
    font-size: 15px;
    color: #d93025;
}

@media screen and (max-width: 768px) {
    .rightSideMainContainer {
        display: none;
    }

    .inputBlock {
        margin: 10px 0;

    }

}