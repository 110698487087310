.mainContainerVideoOn {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    padding: 0;
    z-index: 999;
    background-color: #36373A;
    position: relative;
    cursor: pointer;
    border: 4px solid transparent;
    margin: 0 !important;

}

.mainContainerVideoOff {
    width: 100%;
    height: 100%;
    border: 4px solid transparent;
    margin: auto !important;
    border-radius: 10px;
    padding: 30px;
    z-index: 998;
    background-color: #36373A;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mainUserContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.soundActivity {
    position: absolute;
    top: 10px;
    display: none;

    left: 10px;
}

.userImageContainer {

    border-style: solid;
    border-color: #7e8285;
    position: absolute;
    transition: border-width linear 0.1s;
    border-width: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;

}

.userInfoContainer {
    position: absolute;
    bottom: 20px;

    left: 20px;
}

.userImage {
    height: 120px;
    width: 120px;
    border-radius: 50%;
    background-position: 50% 50%;
    margin: auto;
    background-repeat: no-repeat;
    background-size: cover;

}

.videoContainer {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 10px;
    position: absolute;
    overflow: hidden;

}

.video {
    top: 0;
    left: 0;
    object-fit: fill;
    width: 100%;

}


.userNameText {
    color: white;
    font-weight: 500;
    width: 100%;
    text-align: center;
    margin: 0;
    font-size: 13px;
}

.centerItemIconBlock {
    background-color: #202124;
    z-index: 99;
    width: 25px;
    height: 25px;
    position: absolute;
    top: 10px;
    right: 0px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px;

}

.centerItemIconBlockVoice {
    z-index: 99;
    ;
    position: absolute;
    top: 10px;
    right: 0px;
    margin: 0 10px;
}

.microIcon {
    color: white;
    font-size: 13px;
}


.menuControlsContainer {
    position: absolute !important;
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 40px;
    border-radius: 50px;
    width: 120px;
    background-color: rgba(0, 0, 0, 0.6);
    top: calc(50% - 20px);
    z-index: 999;
    left: calc(50% - 60px);
}

.menuControlItem {
    height: 35px;
    width: 35px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.menuControlItem:hover {
    background-color: rgba(48, 44, 44, 0.8);
}

.controlIcon {
    font-size: 17px;
    color: rgb(209, 209, 209);
    cursor: pointer;
}

.pinIconSlash {
    position: absolute;
    top: 2px;
    color: rgb(209, 209, 209);
    transform: rotate(-40deg);
    font-size: 20px;
    left: calc(50% - 5px);
}

.pinIconSlashTwo {
    position: absolute;
    top: 5px;
    color: #153731;
    transform: rotate(-40deg);
    font-size: 20px;
    left: calc(50% - 5px);
}