.navLogo {
  width: 200px;
  object-fit: cover;
}

.navbar {
  position: fixed;
  padding: 0 !important;
  z-index: 999;
  background-color: white;
  top: 0;
  left: 0;
  transition: background-color ease-out 0.3s;
  box-shadow: inset rgba(255, 255, 255, 0.1) 0px 2px 0px,
    inset rgba(255, 255, 255, 0.04) 0px 0px 0px 2px,
    rgba(0, 0, 0, 0.25) 0px 2px 10px;
}

.navbar2 {
  position: fixed;
  z-index: 99;
  background-color: transparent;
  box-shadow: none;
  top: 85px;
  left: 0;
}

.navItem {
  color: black;
  margin: 0;
  font-weight: 600;
  padding: 10px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  text-decoration: none;
}

.navItemMenu {
  color: black;
  margin: 0;
  font-weight: 600;
  padding-left: 30px;
  padding-right: 30px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 80px; */
}

.dropdownNavItemsToggle {
  padding: 0;
  margin: auto;
  border-radius: 50%;
  height: 42px;
  width: 42px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: transparent;
}

.BasketContainerLight {
  background-color: transparent;
  padding: 10px;
  border-radius: 50%;
  position: relative;
  background-color: #e4e4e4;
}

.BasketContainerDark {
  background-color: transparent;
  padding: 10px;
  border-radius: 50%;
  position: relative;
  background-color: #3b3b3b;
}

.navMainIconsLight {
  font-size: 16px;
  color: black;
  padding: 2px;
}

.navMainIconsDark {
  font-size: 16px;
  color: white;
  padding: 2px;
}

.BasketContainerDark:hover {
  background-color: #3a3b3c;
  border-color: transparent;
}

.BasketContainerDark:hover .navMainIconsLight {
  color: black;
}

.BasketContainerLight:hover {
  background-color: #cccccc;
  border-color: transparent;
}

.BasketContainerLight:hover .navMainIconsDark {
  color: black;
}

.numberMessagesContainer {
  left: 75%;
  top: 55%;
  border-radius: 50%;
  background-color: #fa3e3e;
  height: 18px;
  width: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
}

.numberNotifText {
  color: white !important;
  font-weight: bold !important;
  font-size: 10px !important;
}

.inActiveNavlink {
  color: black;
  font-size: 30px;
  background-color: transparent;
  border-bottom: 2px solid transparent;
  margin: 0 !important;
  padding-bottom: 10px;
  width: 80px;
  border-bottom: 4px solid transparent;
  transition: border 0.1s linear;
}

.Navlink:hover {
  text-decoration: none;
}

.activeNavlink {
  color: black;
  font-weight: 600;
  border-bottom: 4px solid #2474f1;
  width: 80px;
  padding: 0px;
  margin: 0px;
  padding-bottom: 10px;
  transition: border 0.3s linear;
}

.connectProfileItem {
  background-color: #2474f1;
  padding: 5px 10px;
  border-radius: 30px;
}

.connectButton:hover {
  background-color: #2474f1;
  box-shadow: none;
}

.connectButton:focus {
  background-color: #2474f1;
}

.connectButton {
  border-radius: 30px;
  padding: 5px;
  font-size: 14px;
  color: white;
}

.activeLogin {
  background-color: transparent;
  color: black;
}

.notificationCount {
  font-size: 14px;
  font-weight: bold;
  color: white;
}

.activeShoppingBasket {
  background-color: rgb(148, 148, 148);
  color: black;
}

.dropdownMenu {
  width: 300px;
  max-height: 400px;
  overflow-y: auto;
}

.dropdownMenu::-webkit-scrollbar {
  width: 16px;
}

.dropdownMenu::-webkit-scrollbar-track {
  background: transparent;
}

.dropdownMenu::-webkit-scrollbar-thumb {
  height: 6px;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 10px;
  background-color: #ced0d4;
}

.dropdownMenu::-webkit-scrollbar-thumb:hover {
  background-color: #ced0d4;
}

.checkboxContainer {
  display: flex;
  align-items: center;
  padding: 0;
}

.searchBtnContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.searchContainer {
  transition: all 0.3s ease;
}

.iconContainer {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 10px;
}

.dropdown:active {
  background-color: unset !important;
}

.menuActiveMes {
  background: #fff !important;
  border-radius: 8px !important;
  position: absolute !important;
  top: 50px !important;
  right: -10px !important;
  width: 350px !important;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3) !important;
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s !important;

  opacity: 1 !important;
  visibility: visible !important;
  transform: translateY(0) !important;
  z-index: 999999 !important;
}
.menuActiveMes::after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 90%;
  margin-left: -10px;
  border-width: 15px;
  border-style: solid;
  border-color: transparent transparent white transparent;
}
.menuActiveNot {
  background: #fff !important;
  border-radius: 8px !important;
  position: absolute !important;
  top: 50px !important;
  right: -10px !important;
  width: 350px !important;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3) !important;
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s !important;

  opacity: 1 !important;
  visibility: visible !important;
  transform: translateY(0) !important;
  z-index: 999999 !important;
}
.menuActiveNot::after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 90%;
  margin-left: -10px;
  border-width: 15px;
  border-style: solid;
  border-color: transparent transparent white transparent;
}

.menuActive {
  background: #fff !important;
  border-radius: 8px !important;
  position: absolute !important;
  top: 50px !important;
  right: -10px !important;
  width: 270px !important;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3) !important;
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s !important;

  opacity: 1 !important;
  visibility: visible !important;
  transform: translateY(0) !important;
  z-index: 999999 !important;
}
.menuActive::after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 87%;
  margin-left: -10px;
  border-width: 15px;
  border-style: solid;
  border-color: transparent transparent white transparent;
}
.menuUL {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menuLi {
    cursor: pointer;
  padding-left: 10px;
}

.menuLiA {
  text-decoration: none;
  color: #333333;
  font-size: 13px;
  font-weight: 600;
  margin: 0;
  padding: 10px 5px;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .checkboxContainer {
    justify-content: center;
  }

  .searchinputContainer {
    display: flex;
    justify-content: center;
    padding: 0;
    margin: auto;
  }

  .searchBtnContainer {
    justify-content: center;
  }
}
@media screen and (max-width: 991px) {
  .menuActiveMes,
  .menuActiveNot {
    right: -150px !important;
  }
  .menuActive {
    right: -110px !important;
  }
  .menuActive::after,
  .menuActiveMes::after,
  .menuActiveNot::after {
    left: 50% !important;
  }
}
@media screen and (max-width: 994px) {
  .navbar {
    padding: 10px 0 10px 0px !important;
  }
  .iconContainer {
    /* display: block; */
    padding: 10px 0;
  }
  .activeNavlink {
    /* border: none; */
    padding: 5px 0;
    width: 60px !important;
  }
  .inActiveNavlink {
    padding: 5px 0;
    width: fit-content;
  }
  .navLogo {
    padding: 0;
  }
}

.notificationItemContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  cursor: pointer;
  width: 100%;
}

.loadMore {
  font-size: 13px;
  color: #2474f1 !important;
  font-weight: 600;
  margin: 0;
}
