.mainComponentContainer {
    background-color: #202124;
    height: 100vh;
    overflow: hidden;
}


.mainRowContainer {
    height: calc(100vh - 80px);

}

.mainContainer {


    width: calc(100% -20px);
    height: calc(100% -20px);
    margin: 20px !important;

    background-color: #202124;
    position: relative;
}
.reactionText{
    font-size: 14px;
    font-weight: 700;
    color: white;
    margin: 0;
}
.reactionContainer{
    height: 50px;
    min-width: 150px;
    background-color: #202124;
    border-radius: 10px;
    padding: 5px 10px 5px 10px;
    z-index: 999999;
    top: 40px;
    left: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
}




.notificationsContainer {
    position: absolute;
    bottom: 80px;
    z-index: 999;
    right: 20px;
    border-radius: 5px;
}

.mainSideBarContainer {
    transition: all linear .30s;


}

.gridContainer {
    display: grid;
    padding: 10px;
    height: 100%;
}

.gridItem {

    padding: 20px;
    display: flex;
    width: 100%;
    flex-grow: 1 !important;
    align-items: center;
    justify-content: center;
    font-size: 30px;
}

@media screen and (max-width: 768px) {
    .mainSideBarContainer {
        position: absolute !important;
        height: calc(100vh -60px) !important;
        overflow: hidden !important;

        top: 0 !important;
        right: 0 !important;
        width: 100vw !important;
    }

}